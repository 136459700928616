import { css } from '@emotion/core'

import FooterCopyright from './FooterCopyright'
import Paragraph from './Paragraph'
import Span from './Span'

import * as colors from './colors'
import * as breakpoints from './breakpoints'

const root = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1.2rem;
  padding-bottom: 4.5rem;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  background-color: ${colors.NEUTRAL_80};

  @media (${breakpoints.X_SMALL}) {
    padding-bottom: 5rem;
  }
  @media (${breakpoints.MEDIUM}) {
    padding: 3.5rem 1rem;
  }
`

const categoriesWrapper = css`
  width: 100%;
  max-width: 62.5rem;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 2.5em;
  border-bottom: 1px solid ${colors.NEUTRAL_55};

  @media (${breakpoints.SMALL}) {
    flex-direction: row;
  }
`

const categoryContainer = css`
  flex: 0.2;
  padding-top: 1em;

  * {
    padding: 0.4em 0;
  }

  @media (${breakpoints.SMALL}) {
    padding: 0;
  }
`

const linkText = css`
  font-size: 1rem;
  font-family: 'LibreFranklin-Regular';
  line-height: 1.5em;
  color: ${colors.NEUTRAL_30};

  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`

const infoText = css`
  font-size: 1rem;
  font-family: 'LibreFranklin-Regular';
  color: ${colors.NEUTRAL_30};
`

const logo = css`
  width: 23%;
  min-width: 4.5rem;
  object-fit: contain;
  position: relative;
  bottom: -4px;
  padding-bottom: 12px;
  filter: invert(1) brightness(1000%) saturate(0%);
`

const image = css`
  width: 50%;
  min-width: 10rem;
`

const links = [
  {
    'Informations légales': [
      [
        'Conditions générales d’utilisation',
        'https://www.notion.so/Conditions-g-n-rales-d-utilisation-62c912c384f94931a6d432aa4f510c1d',
      ],
      [
        'Fonctionnement de la Place de Marché',
        'https://www.notion.so/Fonctionnement-de-la-Place-de-March-b77b45b619b94e819b5e4e78b7e8440a',
      ],
      [
        'Politique des cookies',
        'https://www.notion.so/Politique-des-Cookies-628fc3b1c04d4a7b8a693a28e9a4d971',
      ],
      [
        'Politique de confidentialité',
        'https://www.notion.so/Politique-de-confidentialit-8d57e545f1094576a9544cace9718b5b',
      ],
    ],
  },
  {
    'À propos de Potico': [
      [
        'FAQ - Aide à la commande',
        'https://www.notion.so/FAQ-Aide-la-commande-3af232f4a60241238719a261cd440672',
      ],
      [
        'À propos',
        'https://www.notion.so/Page-A-propos-Potico-c869901a85f1421ebf112218c2c46074',
      ],
      ['Instagram', 'https://www.instagram.com/potico_fr'],
      ['Facebook', 'https://www.facebook.com/poticofrance'],
      ['payment methods logos', '/pay_methods_dark.png'],
    ],
  },
  {
    'Potico côté Pro': [
      ['Notre site Wino', 'https://www.wino.fr'],
      ['Demander une démo', 'https://wino.fr/demander-une-demo'],
      ['Le Blog', 'https://wino.fr/blog'],
    ],
  },
]

const Footer: React.FC<{}> = () => {
  return (
    <div css={root}>
      <div css={categoriesWrapper} className="flex flex-row">
        {links.map((category, index) => (
          <div key={`category-${index}`} css={categoryContainer} className="flex flex-col">
            <Span size="large" weight="bold" color="light">
              {Object.keys(category)[0]}
            </Span>
            {category[Object.keys(category)[0]].map((content: [string, string]) =>
              content[1].includes('.png') ? (
                <img
                  css={image}
                  key={content[0]}
                  src={content[1]}
                  alt={content[0]}
                  width="160"
                  height="45"
                />
              ) : (
                <a
                  key={content[0]}
                  target="_blank"
                  href={content[1]}
                  rel={'noopener ' + content[1].includes('wino.fr') ? '' : 'nofollow'}
                  css={linkText}
                >
                  {content[0]}
                </a>
              ),
            )}
          </div>
        ))}
        <div css={categoryContainer} className="flex flex-col">
          <img css={logo} src="/logo_potico.png" alt="logo_potico" width="72" height="42" />
          <Paragraph css={infoText}>
            Wino Technologies SAS
            <br />
            13 rue Belzunce 75010 Paris
            <br />
            01 84 25 60 02
            <br />
            potico@wino.fr
            <br />
          </Paragraph>
        </div>
      </div>
      <FooterCopyright />
    </div>
  )
}

export default Footer
