import { css } from '@emotion/core'

import { useCart } from './CartContext'
import { Types } from './CartReducer'

import Button from './Button'

import * as colors from './colors'
import * as breakpoints from './breakpoints'

const header = css`
  display: flex;
  position: fixed;
  z-index: 999;
  bottom: -3px;
  width: 100%;
  height: 60px;
  line-height: 60px;
  padding: 0.4em 1.6em 0.65em 1.6em;
  background: white;
  text-align: center;
  border-top: 1px solid ${colors.NEUTRAL_25};

  > * {
    align-items: center;
  }

  @media (${breakpoints.MEDIUM}) {
    display: none;
  }
`

const CartButtonBar: React.FC<{}> = () => {
  let { state: cart, dispatch: cartDispatch } = useCart()

  return !cart.opened && cart.products.length ? (
    <div css={header}>
      <Button variation="brand" onPress={() => cartDispatch({ type: Types.CartToggled })}>
        Voir commande
      </Button>
    </div>
  ) : null
}

export default CartButtonBar
