import { IconName, svg } from './svg'
import { SerializedStyles } from '@emotion/core'

const Icon: React.FC<IconProps> = (props) => {
  const { name, color, size, css } = props

  return (
    <div css={[{ width: size, height: size }, css]} className={props.className}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <path d={svg[name]} fill={color} />
      </svg>
    </div>
  )
}

type IconProps = {
  className?: string
  name: IconName
  color: string
  size?: number
  css?: SerializedStyles
}

export default Icon
