import { css } from '@emotion/core'

import * as colors from './colors'
import * as breakpoints from './breakpoints'

const raw = css`
  font-family: 'LibreFranklin-Regular';
  font-size: 0.875rem;
  color: ${colors.NEUTRAL_50};
  line-height: 1.465rem;
  @media (${breakpoints.SMALL}) {
    font-size: 0.9375rem;
    line-height: 1.5rem;
  }
`

const tiny = css`
  font-size: 0.8125rem;
  line-height: 1.1875rem;
  color: ${colors.NEUTRAL_80};
  @media (${breakpoints.SMALL}) {
    font-size: 0.8438rem;
    line-height: 1.3125rem;
  }
`

const small = css`
  font-size: 0.8438rem;
  line-height: 1.4375rem;
  color: ${colors.NEUTRAL_80};
  @media (${breakpoints.SMALL}) {
    font-size: 0.875rem;
    line-height: 1.5rem;
  }
`

const medium = css`
  font-size: 1rem;
  line-height: 1.48rem;
  color: ${colors.NEUTRAL_90};
  @media (${breakpoints.SMALL}) {
    font-size: 1.0313rem;
  }
`

const large = css`
  font-size: 1.0625rem;
  line-height: 1.75rem;
  color: ${colors.NEUTRAL_80};
  @media (${breakpoints.SMALL}) {
    font-size: 1.125rem;
  }
`

const huge = css`
  font-size: 1.125rem;
  line-height: 1.75rem;
  color: ${colors.NEUTRAL_80};
  @media (${breakpoints.SMALL}) {
    font-size: 1.25rem;
    line-height: 1.7813rem;
  }
  @media (${breakpoints.LARGE}) {
    font-size: 1.35rem;
    line-height: 1.5em;
  }
`

const Paragraph: React.FC<{
  className?: string
  size?: 'tiny' | 'small' | 'normal' | 'medium' | 'large' | 'huge'
}> = props => {
  const { size } = props

  return (
    <p
      className={props.className}
      css={[
        raw,
        size === 'tiny' && tiny,
        size === 'small' && small,
        size === 'medium' && medium,
        size === 'large' && large,
        size === 'huge' && huge,
      ]}
    >
      {props.children}
    </p>
  )
}

export default Paragraph
