import { css } from '@emotion/core'

import * as grid from './grid'
import * as breakpoints from './breakpoints'

const raw = css`
  margin-left: calc(${grid.MOBILE_GUTTER} + 1.5%);
  margin-right: calc(${grid.MOBILE_GUTTER} + 1.5%);
  max-width: 63.25rem;

  @media (${breakpoints.X_SMALL}) {
    margin: auto;
    margin-left: calc(${grid.DEFAULT_GUTTER} + 1.5%);
    margin-right: calc(${grid.DEFAULT_GUTTER} + 1.5%);
  }
  @media (${breakpoints.LARGE}) {
    margin: auto;
    padding-left: 0;
    padding-right: 0;
  }
`

const small = css`
  max-width: 47rem;
  padding-top: 1.4rem;
  padding-bottom: 0.75rem;

  @media (${breakpoints.MEDIUM}) {
    padding-top: 6rem;
    padding-bottom: 4rem;
  }
`

const large = css`
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;

  @media (${breakpoints.MEDIUM}) {
    padding-top: 3.25rem;
    padding-bottom: 4rem;
  }
`

const Wrapper: React.FC<WrapperProps> = (props) => {
  const { size = 'normal' } = props

  return (
    <div
      css={[
        raw,
        size === 'small' && small,
        size === 'large' && large
      ]}
      className={props.className}
    >
      {props.children}
    </div>
  )
}

type WrapperProps = {
  className?: string
  size?: 'small' | 'normal' | 'large'
}

export default Wrapper
