import { capitalCase } from 'change-case'

export function slugify(str: string) {
  const rExps = [
    { re: /[\xC0-\xC6]/g, ch: 'A' },
    { re: /[\xE0-\xE6]/g, ch: 'a' },
    { re: /[\xC8-\xCB]/g, ch: 'E' },
    { re: /[\xE8-\xEB]/g, ch: 'e' },
    { re: /[\xCC-\xCF]/g, ch: 'I' },
    { re: /[\xEC-\xEF]/g, ch: 'i' },
    { re: /[\xD2-\xD6]/g, ch: 'O' },
    { re: /[\xF2-\xF6]/g, ch: 'o' },
    { re: /[\xD9-\xDC]/g, ch: 'U' },
    { re: /[\xF9-\xFC]/g, ch: 'u' },
    { re: /[\xC7-\xE7]/g, ch: 'c' },
    { re: /[\xD1]/g, ch: 'N' },
    { re: /[\xF1]/g, ch: 'n' },
  ]

  for (let index = 0, length = rExps.length; index < length; index++) {
    str = str.replace(rExps[index].re, rExps[index].ch)
  }

  return str
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^a-z0-9-]/g, '')
    .replace(/-{2,}/g, '-')
}

export function toFixed(num: number, precision: number): number {
  if (String(num).includes('e-')) {
    return 0
  }
  return parseFloat(
    (+(Math.round(+(num + 'e' + precision)) + 'e' + -precision)).toFixed(precision),
  )
}

export function formatAmount(num: number, precision: number | undefined = 2) {
  return num.toFixed(precision).replace('.', ',')
}

export function changeCase(value: string): string {
  return value === value.toUpperCase() ? capitalCase(value) : value
}
