import { createContext, useContext, useReducer } from 'react'

import { CartReducer, InitialStateType, initialState } from './CartReducer'

const context = createContext<{
  state: InitialStateType
  dispatch: React.Dispatch<any>
}>({
  state: initialState,
  dispatch: () => null,
})

export const useCartReducer = () => useReducer(CartReducer, initialState)
export const useCart = () => useContext(context)
export const CartProvider = context.Provider
