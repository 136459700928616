import { useEffect } from 'react'
import { css } from '@emotion/core'

import Modal from 'react-modal'
import Icon from './Icon'
import { IconName } from './svg'

import * as colors from './colors'
import * as breakpoints from './breakpoints'

export const modalStyle = css`
  .addProductModal {
    overflow-y: auto;
    margin: 10px;
    outline: none;
    background: white;
    border-radius: 6px;
    cursor: default;

    @media (${breakpoints.MEDIUM}) {
      width: 72vw;
      min-width: 760px;
      height: fit-content;
      margin: auto;
      padding: 20px;
    }

    @media (${breakpoints.LARGE}) {
      width: 64vw;
    }

    @media (${breakpoints.HUGE}) {
      width: 60vw;
    }
  }
  .addProductModalOverlay {
    display: flex;
    position: fixed;
    z-index: 9999;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: ${colors.NEUTRAL_90_50};
    cursor: pointer;
  }
`

const closeButton = css`
  position: fixed;
  top: 7px;
  right: 3px;
  display: flex;
  width: 100%;
  justify-content: flex-end;

  svg {
    width: 80%;
    opacity: 0.6;
    :hover {
      opacity: 1;
    }
  }

  @media (${breakpoints.MEDIUM}) {
    position: relative;
    top: -5px;
    right: 0;
    opacity: 0.6;
    svg {
      width: 100%;
    }
  }
`

const wrapper = css`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 2em;

  @media (${breakpoints.MEDIUM}) {
    flex-direction: row;
    padding: 0 2em 2.5em 2em;
    max-height: 65vh;
    overflow: hidden;
  }
`

const CustomModal: React.FC<ModalProps> = (props) => {
  const { opened, onRequestClose } = props

  useEffect(() => {
    if (typeof window !== undefined) {
      Modal.setAppElement('body')
    }
  }, [])

  useEffect(() => {
    const containers = document.querySelectorAll<HTMLElement>('#__next > div > div')

    if (opened) {
      document.body.style.overflow = 'hidden'
      if (!window.navigator.platform.match(/^Mac/)) {
        containers.forEach((node) => (node.style.paddingRight = '15px'))
      }
    }
    return () => {
      document.body.style.overflow = 'unset'
      containers.forEach((node) => (node.style.paddingRight = '0'))
    }
  }, [opened])

  return (
    <Modal
      isOpen={opened}
      className="addProductModal"
      overlayClassName="addProductModalOverlay"
      onRequestClose={onRequestClose}
    >
      <div css={closeButton}>
        <button onClick={() => onRequestClose()} aria-label="fermer fiche produit">
          <Icon name={IconName.Close} color={colors.NEUTRAL_70} size={45} />
        </button>
      </div>
      <div css={wrapper} className={props.className}>
        {props.children}
      </div>
    </Modal>
  )
}

type ModalProps = {
  className?: string
  opened: boolean
  onRequestClose: (handleRequestCloseFunc: void) => void
}

export default CustomModal
