import { css } from '@emotion/core'

import * as colors from './colors'
import * as breakpoints from './breakpoints'

const root = css`
  width: 100%;
  max-width: 62.25em;
  flex-direction: column;
  text-align: center;
  padding: 1.5em 1em;

  @media (${breakpoints.MEDIUM}) {
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
    padding-top: 1.5em;
    text-align: left;
  }
`

const copyright = css`
  font-size: 0.825rem;
  font-family: 'LibreFranklin-Regular';
  color: ${colors.NEUTRAL_40};
  padding: 2em 0;
  margin-bottom: -3em;

  @media (${breakpoints.MEDIUM}) {
    margin: 0;
    padding: 0;
  }
`

const prevention = css`
  font-size: 0.75rem;
  font-family: 'Archivo-SemiBold';
  color: ${colors.NEUTRAL_10};
`

const FooterCopyright: React.FC<{}> = props => {
  return (
    <div css={root} className="flex flex-row">
      <div css={prevention} className="flex flex-col">
        L'abus d'alcool est dangereux pour la santé. À consommer avec modération.
        <br />
        La vente d'alcool aux mineurs est interdite.
      </div>
      <span css={copyright}>
        © Copyright {new Date().getFullYear()} - Potico
      </span>
    </div>
  )
}

export default FooterCopyright
