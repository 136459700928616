/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum DiscountUnit {
  EURO = "EURO",
  PERCENTAGE = "PERCENTAGE",
}

export enum OrderBy {
  ASC = "ASC",
  DESC = "DESC",
}

export enum ProductColor {
  AMBER = "AMBER",
  BLACK = "BLACK",
  BLOND = "BLOND",
  DARK = "DARK",
  RED = "RED",
  ROSE = "ROSE",
  WHITE = "WHITE",
}

export enum ProductKind {
  BEER = "BEER",
  COMPOSED = "COMPOSED",
  SIMPLE = "SIMPLE",
  SPIRITUOUS = "SPIRITUOUS",
  WINE = "WINE",
}

export enum ProductPicto {
  BIO = "BIO",
  BIODYNAMICS = "BIODYNAMICS",
  NATURE = "NATURE",
  REASONED = "REASONED",
  VEGAN = "VEGAN",
}

export enum ProductWineType {
  EFFERVESCENT = "EFFERVESCENT",
  STILL = "STILL",
}

export enum ShopCategory {
  BEER = "BEER",
  BUBLES = "BUBLES",
  COMPOSED = "COMPOSED",
  RED_WINE = "RED_WINE",
  ROSE_WINE = "ROSE_WINE",
  SIMPLE = "SIMPLE",
  SPIRITUOUS = "SPIRITUOUS",
  WHITE_WINE = "WHITE_WINE",
}

export interface FranchiseToFilter {
  franchise?: string | null;
}

export interface ShopProductOrderByInput {
  price?: OrderBy | null;
}

export interface ShopProductsFilterInput {
  kind?: ProductKind | null;
  color?: ProductColor | null;
  wineType?: ProductWineType | null;
}

export interface ShopsFilter {
  not?: FranchiseToFilter | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
