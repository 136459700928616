import { css, Global } from '@emotion/core'

import { useCart } from './CartContext'

import { modalStyle } from './Modal'
import TopBar from './TopBar'
import ReinsuranceFooter from './ReinsuranceFooter'
import Footer from './Footer'
import CartButtonBar from './CartButtonBar'

import * as colors from './colors'
import * as breakpoints from './breakpoints'

const global = css`
  body * {
    ::-webkit-scrollbar {
      width: 6px;
      background: ${colors.NEUTRAL_15};
      border-radius: 2px;
    }
    ::-webkit-scrollbar-thumb {
      background: ${colors.NEUTRAL_20};
      border: 1px solid ${colors.NEUTRAL_30};
      border-radius: 2px;
      :hover {
        background: ${colors.NEUTRAL_25};
        border: 1px solid ${colors.NEUTRAL_40};
      }
    }
  }

  button {
    -webkit-tap-highlight-color: transparent;
  }

  @font-face {
    font-family: 'Geomanist-Black';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('/Geomanist-Black.otf') format('opentype');
  }
  @font-face {
    font-family: 'Archivo-Regular';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('https://d33wubrfki0l68.cloudfront.net/845bd94db2c0cbd7ed6823de5d3fa932771263e7/934bf/fonts/archivo-regular.ttf')
      format('truetype');
  }
  @font-face {
    font-family: 'Archivo-SemiBold';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('https://d33wubrfki0l68.cloudfront.net/5f2ab48db26c4202861e2d5423caf4dcbb81d7d6/c8380/fonts/archivo-semibold.ttf')
      format('truetype');
  }
  @font-face {
    font-family: 'Archivo-Bold';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('https://d33wubrfki0l68.cloudfront.net/eeb875e98692cfb8295e936f98b2cc606bbd1210/b038a/fonts/archivo-bold.ttf')
      format('truetype');
  }
  @font-face {
    font-family: 'LibreFranklin-Regular';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('https://d33wubrfki0l68.cloudfront.net/02d21cda1fa69afc0480c4c7566e5acfdd677e6e/252f0/fonts/librefranklin-regular.ttf')
      format('truetype');
  }
  @font-face {
    font-family: 'LibreFranklin-SemiBold';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('https://d33wubrfki0l68.cloudfront.net/e1c7fa6659aef249c2e93131f6b1f9241a84081b/4882f/fonts/librefranklin-semibold.ttf')
      format('truetype');
  }
  @font-face {
    font-family: 'LibreFranklin-Bold';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('https://d33wubrfki0l68.cloudfront.net/e6ec8f165d76d93ad0d482c0c4f4e85e16bc74b6/2f4b9/fonts/librefranklin-bold.ttf')
      format('truetype');
  }
  @font-face {
    font-family: 'Baskerville-SemiBold';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(/fonts/Baskerville-SemiBold.ttf) format('truetype');
  }
  @font-face {
    font-family: 'FiraSans-Medium';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(/fonts/FiraSans-Medium.ttf) format('truetype');
  }
`

const noScroll = css`
  body {
    overflow-y: hidden;
  }
  @media (${breakpoints.MEDIUM}) {
    body {
      overflow-y: auto;
    }
  }
`

const wrapper = css`
  min-height: 90vh;
  margin-top: 60px;
  background-color: ${colors.NEUTRAL_05};
`

const Layout: React.FC<LayoutProps> = (props) => {
  const { shopName, hideCart, franchise } = props
  const { state } = useCart()

  return (
    <div>
      <Global styles={[global, modalStyle, state.opened && noScroll]}></Global>
      <div css={wrapper}>{props.children}</div>
      <TopBar shopName={shopName} hideCart={hideCart} franchise={franchise} />
      <CartButtonBar />
      <ReinsuranceFooter />
      <Footer />
    </div>
  )
}

type LayoutProps = {
  shopName?: string
  hideCart?: boolean
  franchise?: string
}

export default Layout
