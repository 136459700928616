import Image from 'next/image'
import { css } from '@emotion/core'

import * as colors from './colors'
import * as breakpoints from './breakpoints'

const root = css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row: auto auto;
  grid-row-gap: 1.5em;
  padding: 2rem 1.6rem;
  background-color: white;

  @media (${breakpoints.X_SMALL}) {
    padding-bottom: 5rem;
  }
  @media (${breakpoints.MEDIUM}) {
    display: flex;
    flex-direction: row;
    max-width: 62.5rem;
    margin: 0 auto;
    padding: 4.5rem 1rem;
  }
`

const element = css`
  text-align: center;
  padding-left: 0.2em;
  padding-right: 0.2em;

  h1 {
    font-family: 'Archivo-Bold';
    font-size: 0.9rem;
    line-height: 1.05rem;
    color: ${colors.NEUTRAL_90};
  }

  span {
    justify-content: flex-start;
    font-family: 'LibreFranklin-Regular';
    font-size: 0.8rem;
    line-height: 1rem;
    color: ${colors.NEUTRAL_40};
  }

  img {
    height: 75px;
  }

  @media (${breakpoints.MEDIUM}) {
    width: 25%;
    padding-left: 0.6em;
    padding-right: 0.6em;

    h1 {
      font-size: 1rem;
      line-height: 1.3rem;
    }

    span {
      font-size: 0.9rem;
      line-height: 1.15rem;
    }

    img {
      height: auto;
    }
  }
`

const Footer: React.FC<{}> = () => {
  return (
    <div css={root}>
      <div css={element} className="flex flex-col">
        <Image
          src="/icon_clickandcollectfree.png"
          alt="Click'n'Collect image"
          width="134"
          height="89"
          objectFit="contain"
          quality="30"
        />
        <h1 className="pt-4 pb-2">Click and collect gratuit</h1>
        <span className="flex flex-col">
          Retrait en magasin selon les conditions du commerçant
        </span>
      </div>
      <div css={element} className="flex flex-col">
        <Image
          src="/icon_paymentsecured.png"
          alt="Paiement image"
          width="134"
          height="89"
          objectFit="contain"
          quality="30"
        />
        <h1 className="pt-4 pb-2">Paiement sécurisé</h1>
        <span className="flex flex-col">Paiement 100% sécurisé par carte bancaire</span>
      </div>
      <div css={element} className="flex flex-col">
        <Image
          src="/icon_proximity.png"
          alt="Proximité image"
          width="134"
          height="89"
          objectFit="contain"
          quality="30"
        />
        <h1 className="pt-4 pb-2">Proximité & savoir-faire</h1>
        <span className="flex flex-col">
          Commander en direct de vos commerçants préférés
        </span>
      </div>
      <div css={element} className="flex flex-col">
        <Image
          src="/icon_delivery.png"
          alt="Livraison image"
          width="134"
          height="89"
          objectFit="contain"
          quality="30"
        />
        <h1 className="pt-4 pb-2">Livraison chez vous</h1>
        <span className="flex flex-col">Livraison bientôt disponible avec Potico</span>
      </div>
    </div>
  )
}

export default Footer
