import { css } from '@emotion/core'

import * as colors from './colors'
import * as breakpoints from './breakpoints'

const raw = css`
  font-family: 'LibreFranklin-Regular';
  font-size: 0.9688rem;
  color: ${colors.NEUTRAL_50};
  line-height: 1.625rem;
  @media (${breakpoints.SMALL}) {
    font-size: 1rem;
    line-height: 1.6875rem;
  }
`

const small = css`
  font-size: 0.8125rem;
  line-height: 1.4375rem;
  color: NEUTRAL_COLOR_80;
  @media (${breakpoints.SMALL}) {
    font-size: 0.875rem;
    line-height: 1.5rem;
  }
`

const medium = css`
  font-size: 1rem;
  line-height: 1.48rem;
  color: ${colors.NEUTRAL_90};
  @media (${breakpoints.SMALL}) {
    fontsize: 1.0313rem;
  }
`

const large = css`
  font-size: 1.0625rem;
  line-height: 1.75rem;
  @media (${breakpoints.SMALL}) {
    font-size: 1.125rem;
    line-height: 1.8125rem;
  }
`

const huge = css`
  font-size: 1.1875rem;
  line-height: 1.75rem;
  @media (${breakpoints.SMALL}) {
    font-size: 1.25rem;
    line-height: 1.8125rem;
  }
`

const semiBold = css`
  font-family: 'LibreFranklin-SemiBold';
`
const bold = css`
  font-family: 'LibreFranklin-SemiBold';
`
const italic = css`
  font-family: 'LibreFranklin-regular';
  font-style: italic;
`

const Span: React.FC<SpanProps> = (props) => {
  const { size, weight, color, font } = props

  return (
    <span
      css={[
        raw,
        size === 'small' && small,
        size === 'medium' && medium,
        size === 'large' && large,
        size === 'huge' && huge,
        weight === 'semibold' && semiBold,
        weight === 'bold' && bold,
        weight === 'italic' && italic,
        color === 'brand' && { color: colors.BRAND_50 },
        color === 'light' && { color: colors.NEUTRAL_00 },
        color === 'dark' && { color: colors.NEUTRAL_30 },
        color === 'grey' && { color: colors.NEUTRAL_40 },
        color === 'darker' && { color: colors.NEUTRAL_55 },
        font === 'Archivo-Regular' && { fontFamily: 'Archivo-Regular' },
      ]}
      className={props.className}
    >
      {props.children}
    </span>
  )
}

type SpanProps = {
  className?: string
  size?: 'small' | 'normal' | 'medium' | 'large' | 'huge'
  weight?: 'regular' | 'semibold' | `bold` | 'italic'
  color?: 'regular' | `brand` | 'light' | 'dark' | 'darker' | 'grey'
  font?: 'Archivo-Regular'
}

export default Span
