import { css } from '@emotion/core'

import * as colors from './colors'

const raw = css`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  justify-content: center;
  cursor: pointer;
  border-radius: 0.3125rem;
  box-sizing: 'border-box';
  vertical-align: 'middle';
  text-decoration: 'none';
  font-family: 'LibreFranklin-SemiBold';
  align-items: center;
  font-size: 1rem;
`

const normal = css`
  background-color: transparent;
  border: 1px solid ${colors.NEUTRAL_25};
  color: ${colors.NEUTRAL_90};

  :hover {
    color: ${colors.NEUTRAL_90};
    border: 1px solid ${colors.NEUTRAL_65};
  }
`

const important = css`
  background-color: white;
  border: 2px solid ${colors.NEUTRAL_25};
  font-family: 'Archivo-Bold';
  color: ${colors.NEUTRAL_90};

  :hover {
    color: ${colors.NEUTRAL_90};
    border: 2px solid ${colors.NEUTRAL_55};
  }
`

const brand = css`
  color: ${colors.NEUTRAL_00};
  background-color: ${colors.BRAND_50};

  :hover {
    background-color: ${colors.BRAND_60};
  }
`

const Button: React.FC<ButtonProps> = (props) => {
  const { variation = 'normal', onPress, disabled } = props

  return (
    <button
      disabled={disabled}
      onClick={() => (onPress ? onPress() : null)}
      css={[
        raw,
        variation === 'normal' && normal,
        variation === 'important' && important,
        variation === 'brand' && brand,
      ]}
      className={props.className}
    >
      <strong>{props.children}</strong>
    </button>
  )
}

type ButtonProps = {
  className?: string
  disabled?: boolean
  variation?: 'normal' | 'important' | 'brand'
  onPress?: (handleRequestPressFunc: void) => void
}

export default Button
