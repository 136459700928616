import Link from 'next/link'
import { Fragment } from 'react'
import { css } from '@emotion/core'

import { changeCase } from './utils'

import { useCart } from './CartContext'
import { Types } from './CartReducer'

import { IconName } from './svg'
import Icon from './Icon'

import * as colors from './colors'
import * as breakpoints from './breakpoints'

const container = css`
  position: fixed;
  z-index: 999;
  top: 0;
  height: 60px;
  width: 100%;
  background-color: white;
  border-bottom: 1px solid ${colors.NEUTRAL_20};
  box-shadow: 0px 20px 20px -25px #ccc;
`

const wrapper = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 60rem;
  height: 100%;
  line-height: 100%;
  margin: auto;
  padding: 0 1.5em;

  @media (${breakpoints.LARGE}) {
    padding: 0;
  }
`

const image = css`
  width: 75px;
  height: 24px;

  @media (${breakpoints.SMALL}) {
    width: 87px;
    height: 28px;
  }
`

const imageCavavin = css`
  width: 88px;
  height: auto;

  @media (${breakpoints.SMALL}) {
    width: 98px;
    height: auto;
  }
`

const withSeparator = css`
  margin: 0 0.4em;
`

const separator = css`
  height: 1px;
  width: 1.5em;
  background: ${colors.NEUTRAL_25};
  margin: 0 0.8em;
`

const shopNameStyle = css`
  font-family: 'LibreFranklin-Regular';
  font-size: 1rem;
  color: ${colors.NEUTRAL_90};
  text-transform: capitalize;
`

const cartButton = css`
  display: none;
  align-items: center;
  padding: 2px 0;
  font-family: 'LibreFranklin-Regular';
  font-size: 1rem;
  color: ${colors.NEUTRAL_90};

  :hover {
    font-family: 'LibreFranklin-SemiBold';
    text-decoration: underline;
    color: ${colors.BRAND_60};
  }

  @media (${breakpoints.MEDIUM}) {
    display: flex;
  }
`

const cartButtonDisabled = css`
  pointer-events: none;
  cursor: default;

  :hover {
    font-family: 'LibreFranklin-Regular';
    text-decoration: none;
    color: ${colors.NEUTRAL_90};
  }
`

const cartIcon = css`
  margin-top: -3px;
`

const TopBar: React.FC<TopBarProps> = (props) => {
  const { shopName, hideCart = false, franchise } = props
  const { state: cart, dispatch: cartDispatch } = useCart()

  return (
    <div css={container}>
      <div css={wrapper}>
        <div className="flex flex-row items-center">
          {franchise === 'Cavavin' ? (
            <Fragment>
              <img css={image} src="/logo_potico.png" alt="Potico" width="87" height="28" />
              <Icon
                name={IconName.Close}
                color={colors.NEUTRAL_40}
                size={26}
                css={withSeparator}
              />
              <img
                css={imageCavavin}
                src="/logo_cavavin.png"
                alt="Cavavin"
                width="98"
                height="auto"
              />
            </Fragment>
          ) : (
            <Link href="/" passHref={true}>
              <a>
                <img
                  css={image}
                  src="/logo_potico.png"
                  alt="Potico"
                  width="87"
                  height="28"
                />
              </a>
            </Link>
          )}
          {shopName && (
            <Fragment>
              <div css={separator}></div>
              <span css={shopNameStyle}>{changeCase(shopName)}</span>
            </Fragment>
          )}
        </div>
        {!hideCart && cart && (
          <button
            css={[cartButton, !cart.products.length && cartButtonDisabled]}
            onClick={() =>
              cartDispatch({
                type: Types.CartToggled,
              })
            }
            aria-label="ouvrir panier"
          >
            <span className="px-2">
              {cart.totalPriceToPay.toFixed(2).toString().replace('.', ',')} €
            </span>
            <Icon
              css={cartIcon}
              name={IconName.Basket}
              color={cart.products.length ? colors.BRAND_50 : colors.NEUTRAL_40}
              size={24}
            />
          </button>
        )}
      </div>
    </div>
  )
}

type TopBarProps = {
  shopName?: string
  hideCart?: boolean
  franchise?: string
}

export default TopBar
