export enum IconName {
  Close = 'close',
  Delete = 'delete',
  TickSign = 'tick_sign',
  ArrowLine = 'arrow_line',
  Basket = 'basket',
  ArrowLeft = 'arrow_left',
  ArrowRight = 'arrow_right',
  ArrowDown = 'arrow_down',
  Arrow = 'arrow',
}

export const svg = {
  close:
    'M9.096 9.804l-4.949-4.95a.499.499 0 010-.707.499.499 0 01.707 0l4.949 4.95 4.95-4.95a.499.499 0 01.707 0 .499.499 0 010 .707l-4.95 4.95 4.95 4.95a.499.499 0 010 .707.499.499 0 01-.707 0l-4.95-4.95-4.949 4.95a.499.499 0 01-.707 0 .499.499 0 010-.707l4.949-4.95z',
  tick_sign:
    'M8.5 0a8.5 8.5 0 110 17 8.5 8.5 0 010-17zm4.668 5.237a1 1 0 00-1.413.054l-4.373 4.712-2.14-2.46-.088-.09a1 1 0 00-1.42 1.402l2.87 3.301.088.09a1 1 0 001.4-.066l5.13-5.53.079-.097a1 1 0 00-.133-1.316z',
  arrow_line:
    'M14.1 4.3c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4L15.9 9H2c-.6 0-1 .4-1 1s.4 1 1 1h13.9l-3.3 3.3c-.4.4-.4 1 0 1.4.2.2.5.3.7.3.3 0 .5-.1.7-.3l5-5c.4-.4.4-1 0-1.4l-4.9-5z',
  basket:
    'M11.6923865,0.25 C13.7629769,0.25 15.442,1.92681222 15.442,3.99536918 L15.442,3.99536918 L15.4413123,5.25 L18.1773077,5.25 C18.5627777,5.25 18.8854794,5.54220095 18.9236261,5.92577878 L20.1191804,17.9474648 C20.2148153,18.9129851 19.4563095,19.75 18.4859679,19.75 L1.86864748,19.75 C0.898305878,19.75 0.139800042,18.9129851 0.235464335,17.9471694 L0.235464335,17.9471694 L1.43098925,5.92577878 C1.46913599,5.54220095 1.79183768,5.25 2.17730769,5.25 L2.17730769,5.25 L4.94131225,5.25 L4.942,3.99536918 C4.942,1.9936244 6.51508804,0.35859453 8.49326211,0.255191713 L8.69238655,0.25 C9.2259647,0.25 9.73354042,0.361350493 10.1930822,0.562060858 C10.6518782,0.361196127 11.1591697,0.25 11.6923865,0.25 Z M4.34831225,6.75 L2.85642006,6.75 L1.72813052,18.0953166 C1.71992602,18.1781484 1.78503823,18.25 1.86864748,18.25 L1.86864748,18.25 L3.2331697,18.2502899 C3.2247768,18.1512775 3.22528361,18.0499529 3.23546434,17.9471694 L4.34831225,6.75 Z M17.4981953,6.75 L5.85642006,6.75 L4.72813052,18.0953166 C4.71992602,18.1781484 4.78503823,18.25 4.86864748,18.25 L18.4859679,18.25 C18.5695772,18.25 18.6346894,18.1781484 18.6265142,18.0956119 L17.4981953,6.75 Z M8.69064019,1.74994828 L8.53831606,1.75518314 C7.36735886,1.83422964 6.442,2.80753421 6.442,3.99536918 L6.442,3.99536918 L6.44131225,5.25 L7.94131225,5.25 L7.942,3.99536918 C7.942,3.15302423 8.22055796,2.37561611 8.69064019,1.74994828 Z M10.1925759,2.32221382 L10.1011386,2.4078796 C9.69389353,2.8142869 9.442,3.37562919 9.442,3.99536918 L9.442,3.99536918 L9.44131225,5.25 L10.9413123,5.25 L10.942,3.99536918 C10.942,3.33075917 10.6525706,2.73340572 10.1925759,2.32221382 Z M11.6923865,1.75 L11.7619035,1.84381487 C12.1904086,2.45260629 12.442,3.19463745 12.442,3.99536918 L12.442,3.99536918 L12.4413123,5.25 L13.9413123,5.25 L13.942,3.99536918 C13.942,2.80733574 13.017161,1.8342131 11.8464293,1.75518211 L11.6923865,1.75 L11.6923865,1.75 Z',
  arrow_left:
    'M11.328 15.698a.5.5 0 11-.712.702l-5.972-6.049a.5.5 0 01.002-.704l5.972-6a.5.5 0 11.708.706L5.704 10l5.624 5.697z',
  arrow_right:
    'M14.268 10.048L8.644 4.35a.5.5 0 11.712-.702l5.972 6.049a.5.5 0 01-.002.704l-5.972 6a.5.5 0 01-.708-.706l5.622-5.648z',
  delete:
    'M12 .5a.5.5 0 01.5.5v1.5H18a.5.5 0 01.492.41L18.5 3a.5.5 0 01-.5.5h-1.117l-1.457 15.547a.5.5 0 01-.497.453H5.07a.5.5 0 01-.497-.453L3.116 3.5H2a.5.5 0 01-.492-.41L1.5 3a.5.5 0 01.5-.5h5.5V1A.5.5 0 018 .5h4zm-7.88 3l1.407 15h8.946l1.406-15H4.12zm7.38 3a.5.5 0 01.5.5v8a.5.5 0 11-1 0V7a.5.5 0 01.5-.5zm-3 0A.5.5 0 019 7v8a.5.5 0 11-1 0V7a.5.5 0 01.5-.5zm3-5h-3v1h3v-1z',
  arrow_down:
    'M6.667 4.333a.5.5 0 01.708-.708l6 6a.5.5 0 010 .706l-6 6.042a.5.5 0 01-.709-.704l5.65-5.689-5.649-5.647z',
  arrow:
    'M 90 24.25 c 0 -0.896 -0.342 -1.792 -1.025 -2.475 c -1.366 -1.367 -3.583 -1.367 -4.949 0 L 45 60.8 L 5.975 21.775 c -1.367 -1.367 -3.583 -1.367 -4.95 0 c -1.366 1.367 -1.366 3.583 0 4.95 l 41.5 41.5 c 1.366 1.367 3.583 1.367 4.949 0 l 41.5 -41.5 C 89.658 26.042 90 25.146 90 24.25 z',
}
