import { toFixed } from './utils'

import { DiscountUnit } from '__generated__/globalTypes'
import { discount_discount } from 'core/queries/__generated__/discount'

export type ProductType = {
  id: string
  name: string
  variantName: string
  quantity: number
  crossedPrice: number | null
  unitPrice: number
  totalPrice: number
  stock: number
}

export enum Types {
  ProductQuantityChanged = 'PRODUCT_QUANTITY_CHANGED',
  ProductAdded = 'PRODUCT_ADDED',
  ProductDeleted = 'PRODUCT_DELETED',
  CartToggled = 'CART_TOGGLED',
  DiscountAdded = 'DISCOUNT_ADDED',
  DiscountDeleted = 'DISCOUNT_DELETED',
  DeliveryFeesToggled = 'DELIVERY_FEES_TOGGLED',
}

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key
      }
    : {
        type: Key
        payload: M[Key]
      }
}

type CartPayload = {
  [Types.ProductQuantityChanged]: undefined
  [Types.ProductAdded]: undefined
  [Types.ProductDeleted]: undefined
  [Types.CartToggled]: undefined
  [Types.DiscountAdded]: undefined
  [Types.DiscountDeleted]: undefined
  [Types.DeliveryFeesToggled]: undefined
}

export type CartActions = ActionMap<CartPayload>[keyof ActionMap<CartPayload>]

export type InitialStateType = {
  products: ProductType[]
  totalPriceExcludingDiscount: number
  totalPriceIncludingDiscount: number
  totalPriceToPay: number
  opened: boolean
  discount: discount_discount
  deliveryFees: number
}

export const initialState = {
  products: [],
  totalPriceExcludingDiscount: 0,
  totalPriceIncludingDiscount: 0,
  totalPriceToPay: 0,
  opened: false,
  discount: null,
  deliveryFees: 0,
}

function computeCartTotalPriceExcludingDiscount(
  products: ProductType[],
  deliveryFees: number,
) {
  return toFixed(
    products.reduce((acc, current) => acc + current.totalPrice, 0) + deliveryFees,
    2,
  )
}

function computeCartTotalPriceIncludingDiscount(
  totalPriceExcludingDiscount: number,
  discount: discount_discount | null,
) {
  if (discount && discount.minimumOrderValue <= totalPriceExcludingDiscount) {
    if (discount.unit === DiscountUnit.PERCENTAGE) {
      return toFixed((totalPriceExcludingDiscount * (100 - discount.value)) / 100, 2)
    } else return toFixed(totalPriceExcludingDiscount - discount.value, 2)
  } else return toFixed(totalPriceExcludingDiscount, 2)
}

export const CartReducer = (state: InitialStateType, { type, payload }) => {
  switch (type) {
    case Types.CartToggled:
      return {
        ...state,
        opened: !state.opened,
      }
    case Types.ProductQuantityChanged: {
      const nextProducts = state.products.map((product) => {
        return product.id !== payload.id
          ? product
          : {
              ...product,
              totalPrice: toFixed(product.unitPrice * payload.quantity, 2),
              quantity: payload.quantity,
            }
      })
      const totalPriceExcludingDiscount = computeCartTotalPriceExcludingDiscount(
        nextProducts,
        state.deliveryFees,
      )
      const totalPriceIncludingDiscount = computeCartTotalPriceIncludingDiscount(
        totalPriceExcludingDiscount,
        state.discount,
      )

      return {
        ...state,
        products: nextProducts,
        totalPriceExcludingDiscount,
        totalPriceIncludingDiscount,
        totalPriceToPay: totalPriceIncludingDiscount,
      }
    }
    case Types.ProductAdded: {
      const inCartProduct = state.products.find((product) => product.id === payload?.id)
      const nextProducts = inCartProduct
        ? state.products.map((product) => {
            return product.id !== inCartProduct.id
              ? product
              : {
                  ...product,
                  totalPrice: toFixed(
                    product.unitPrice * (product.quantity + payload.quantity),
                    2,
                  ),
                  quantity: product.quantity + payload.quantity,
                }
          })
        : [
            ...state.products,
            {
              id: payload.id,
              name: payload.name,
              variantName: payload.variantName,
              unitPrice: toFixed(payload.price, 2),
              crossedPrice:
                payload.crossedPrice !== null ? toFixed(payload.crossedPrice, 2) : null,
              totalPrice: toFixed(payload.price * payload.quantity, 2),
              quantity: payload.quantity,
              stock: payload.stock,
            },
          ]
      const totalPriceExcludingDiscount = computeCartTotalPriceExcludingDiscount(
        nextProducts,
        state.deliveryFees,
      )
      const totalPriceIncludingDiscount = computeCartTotalPriceIncludingDiscount(
        totalPriceExcludingDiscount,
        state.discount,
      )

      return {
        ...state,
        products: nextProducts,
        totalPriceExcludingDiscount,
        totalPriceIncludingDiscount,
        totalPriceToPay: totalPriceIncludingDiscount,
      }
    }
    case Types.ProductDeleted: {
      const nextProducts = state.products.filter((product) => product.id !== payload.id)
      if (nextProducts.length === 0) {
        return initialState
      }
      const totalPriceExcludingDiscount = computeCartTotalPriceExcludingDiscount(
        nextProducts,
        state.deliveryFees,
      )
      const totalPriceIncludingDiscount = computeCartTotalPriceIncludingDiscount(
        totalPriceExcludingDiscount,
        state.discount,
      )
      return {
        ...state,
        products: nextProducts,
        totalPriceExcludingDiscount,
        totalPriceIncludingDiscount,
        totalPriceToPay: totalPriceIncludingDiscount,
      }
    }
    case Types.DiscountAdded: {
      const totalPriceExcludingDiscount = computeCartTotalPriceExcludingDiscount(
        state.products,
        state.deliveryFees,
      )
      const totalPriceIncludingDiscount = computeCartTotalPriceIncludingDiscount(
        totalPriceExcludingDiscount,
        payload.discount,
      )
      return {
        ...state,
        discount: payload.discount,
        totalPriceExcludingDiscount,
        totalPriceIncludingDiscount,
        totalPriceToPay: totalPriceIncludingDiscount,
      }
    }
    case Types.DiscountDeleted: {
      const totalPriceExcludingDiscount = computeCartTotalPriceExcludingDiscount(
        state.products,
        state.deliveryFees,
      )
      return {
        ...state,
        discount: null,
        totalPriceExcludingDiscount,
        totalPriceIncludingDiscount: totalPriceExcludingDiscount,
        totalPriceToPay: totalPriceExcludingDiscount,
      }
    }
    case Types.DeliveryFeesToggled: {
      const deliveryFees = payload.feesAdded ? payload.deliveryFees : 0
      const totalPriceExcludingDiscount = computeCartTotalPriceExcludingDiscount(
        state.products,
        deliveryFees,
      )
      const totalPriceIncludingDiscount = computeCartTotalPriceIncludingDiscount(
        totalPriceExcludingDiscount,
        state.discount,
      )
      return {
        ...state,
        deliveryFees,
        totalPriceExcludingDiscount,
        totalPriceIncludingDiscount,
        totalPriceToPay: totalPriceIncludingDiscount,
      }
    }

    default:
      return state
  }
}
